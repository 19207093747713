<template>
  <div>
    <!-- <Headers :title="title" :show="false" :returnShow="true"></Headers> -->
    <div class="content">
      <div style="width:100%;height: 100%;overflow: hidden;overflow-y: auto;">
        <div v-if="listdata">
          <span class="text-title">{{listdata.text}}</span>
        </div>
        <el-table v-if="listdata" :data="listdata.list" style="width: 100%">
            <el-table-column prop="0" :label="listdata.title[0].name" :align="listdata.title[0].align" :width="listdata.title[0].width"></el-table-column>
            
            <el-table-column prop="1" :label="listdata.title[1].name" :align="listdata.title[1].align" :width="listdata.title[1].width"></el-table-column>
            
            <el-table-column prop="2" :label="listdata.title[2].name" :align="listdata.title[2].align" :width="listdata.title[2].width">
                <template slot-scope="scope">
                    <div v-html="scope.row[2]"></div>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="listdata1">
          <span class="text-title">{{listdata1.text}}</span>
        </div>
        <el-table v-if="listdata1" :data="listdata1.list" style="width: 100%">
            <el-table-column prop="0" :label="listdata1.title[0].name" :align="listdata1.title[0].align" :width="listdata1.title[0].width"></el-table-column>
            <el-table-column prop="1" :label="listdata1.title[1].name" :align="listdata1.title[1].align" :width="listdata1.title[1].width"></el-table-column>
            <el-table-column prop="2" :label="listdata1.title[2].name" :align="listdata1.title[2].align" :width="listdata1.title[2].width">
                <template slot-scope="scope">
                    <div v-html="scope.row[2]"></div>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="listdata2">
          <span class="text-title">{{listdata2.text}}</span>
        </div>
        <el-table v-if="listdata2" :data="listdata2.list" style="width: 100%">
            <el-table-column prop="0" :label="listdata2.title[0].name" :align="listdata2.title[0].align" :width="listdata2.title[0].width"></el-table-column>
            <el-table-column prop="1" :label="listdata2.title[1].name" :align="listdata2.title[1].align" :width="listdata2.title[1].width"></el-table-column>
            <el-table-column prop="2" :label="listdata2.title[2].name" :align="listdata2.title[2].align" :width="listdata2.title[2].width">
                <template slot-scope="scope">
                    <div v-html="scope.row[2]"></div>
                </template>
            </el-table-column>
        </el-table>
      </div>
        <!-- <table class="el-table el-table__body el-table--border">
            <tbody>
                <tr class="el-table__row">
                    <td class="cell fontw" v-for="(item,index) in listdata.title" :key="index">{{item}}</td>
                </tr>
                <tr class="el-table__row" v-for="(item,index) in listdata.list" :key="index">
                    <td class="cell">{{item[0]}}</td>
                    <td class="cell">{{item[1]}}</td>
                    <td class="cell"><div v-html="item[2]"></div></td>
                </tr>
            </tbody>
        </table> -->
    </div>
  </div>
</template>
<script>
// import Headers from "@/components/headers"
import {
    radar_sj,
    radar_zs,
    radar_al,
    radar_al1,
    radar_al2,
    radar_gs,
    radar_gz,
    radar_sf,
    radar_gj,
    radar_mx
} from "../views/utils/radardata"
export default {
  name: "Org",
  components: {
    // Headers
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
        listdata:null,
        listdata1:null,
        listdata2:null,
    }
  },
  watch:{
    type(){
      this.getdata()
    }
  },
  mounted () {
      this.getdata()
  },
  methods: {
      getdata(){
        this.listdata=null
        this.listdata1=null
        this.listdata2=null
        switch(this.type){
            case 'radar-sj':
                this.listdata={...radar_sj}
            break
            case 'radar-zs':
                this.listdata={...radar_zs}
            break
            case 'radar-al':
                this.listdata={...radar_al}
                this.listdata1={...radar_al1}
                this.listdata2={...radar_al2}
            break
            case 'radar-gs':
                this.listdata={...radar_gs}
            break
            case 'radar-gz':
                this.listdata={...radar_gz}
            break
            case 'radar-sf':
                this.listdata={...radar_sf}
            break
            case 'radar-gj':
                this.listdata={...radar_gj}
            break
            case 'radar-mx':
                this.listdata={...radar_mx}
            break
        }
      }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 800px;
  // padding: 20px 0px;
  overflow-y:auto ;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
/deep/ .el-table .cell {
  background-color: transparent;
  line-height: 32px !important;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.text-title{
  font-size: 30px;
  color: #cbe8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: bold;
}
</style>
