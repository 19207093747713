<template>
  <div id="app">
    <!-- <dv-full-screen-container> -->
    <!-- <div class="largebody"> -->
    <!-- <div class="largebg"></div> -->
    <router-view />
    <!-- </div> -->
    <!-- </dv-full-screen-container> -->
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      widths: document.body.clientWidth,
      heighs: document.body.clientHeight,
      bili: document.body.clientWidth / document.body.clientHeight,
      styles: "",
    };
  },
  mounted() {
    var widths = document.documentElement.clientWidth;
    var heights = document.documentElement.clientHeight;
    document.body.style.transform =
      "scale(" + widths / 1920 + "," + heights / 1080 + ")";
    window.onresize = () => {
      widths = document.documentElement.clientWidth;
      heights = document.documentElement.clientHeight;
      //调用methods中的事件
      document.body.style.transform =
        "scale(" + widths / 1920 + "," + heights / 1080 + ")";
    };
  },
};
</script>
<style>
html {
  width: 100%;
  height: 100%;
  background-image: url(assets/img/bg-new.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
body {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  transform: scale(1);
  transform-origin: left top;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
html,
body,
div,
span,
input,
img,
textarea,
i,
s,
p,
b,
h1,
h2,
h3,
h4,
h5,
h6,
button,
ol,
ul,
li,
a,
font {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}
.dv-loading {
  position: absolute;
  z-index: 9999;
  background-color: #eee;
}

.hoverimage {
  /* position: relative; */
  border: 0 !important;
  background-color: unset !important;
  /* background: url(./assets/img/dataVindex7.png) no-repeat center center;
  background-size: 100% 100%; */
  background-image: url(./assets/img/mapTooltip.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: unset !important;
  /* box-sizing: border-box; */
  /* z-index: 99; */
  top: 10px !important;
  left: 10px !important;
  z-index: 999 !important;
}
</style>
