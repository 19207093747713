<template>
  <div class="large-head">
    <div class="large-head-title">
      <a href="javascript:;" style="color:#fff;cursor: unset;" v-if="!thref">{{title}}</a>
      <a :href="thref" v-if="thref" style="color:#fff;" target="_blank">{{title}}</a>
      <!-- <div style="display: flex;flex-wrap: wrap;width: 100px;" v-show="show">
        <a class="img" v-show="show" target="_blank" title="产品图谱" @click="openWindow('zzytc1')"></a>
        <a class="img" v-show="show" target="_blank" title="逻辑思维" @click="openWindow('zzytc2')"></a>
        <a class="img" target="_blank" title="公式" @click="openWindow('zzytc14')"></a>
      </div> -->
    </div>
    <div class="return" v-show="returnShow" @click="close()"></div>
  </div>
</template>
<script>
import router from "../router";
export default {
  name: "headers",
  props: {
    title: {
      type: String,
    },
    thref: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    returnShow: {
      type: Boolean,
      default: false,
    },
    smallTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    openWindow(url) {
      let imgUrl = router.resolve({ path: "./image",query:{url:url}});
      window.open(imgUrl.href);
    },
    close() {
      window.location.href = "about:blank";
      window.close();
    },
  },
};
</script>
<style scoped>
.large-head {
  width: 100%;
  height: 80px;
  background-image: url(../assets/imgs/wz8.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  position: relative;
  margin-bottom: 5px;
  text-align: center;
}
.large-head-title {
  text-align: center;
  font-size: 42px;
  justify-content: center;
  height: 50px;
  color: #fff;
  font-family: SimHei;
  font-weight: bold;
  padding-top: 12px;
  display: flex;
  letter-spacing: 2px;
}
.img {
  height: 30px;
  width: 30px;
  background-image: url(../assets/img/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  margin: 5px 10px;
  cursor: pointer;
}

.return {
  background-image: url(../assets/img/return.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 100px;
  height: 35px;
  margin-left: 3vh;
  margin-top: -8px;
  cursor: pointer;
}

.small-title {
  font-size: 24px;
  color: #fff;
  margin-left: 5px;
  margin-top: 10px;
}
</style>