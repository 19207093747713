const ulist = [{
  title: "数据",
  value: "358万条",
  url: "radar-sj",
},
{
  title: "知识",
  value: "23项",
  url: "radar-zs",
},
{
  title: "案例",
  value: "407个",
  url: "radar-al",
},
{
  title: "规则",
  value: "10个",
  url: "radar-gz",
},
{
  title: "公式",
  value: "3个",
  url: "radar-gs",
},
{
  title: "算法",
  value: "8个",
  url: "radar-sf",
},
{
  title: "工具",
  value: "5个",
  url: "radar-gj",
},
{
  title: "模型",
  value: "3个",
  url: "radar-mx",
},]

const topList1 = [
  { name: "数据清洗", num: "2260", unit: "个" },
  { name: "图谱构建", num: "49", unit: "项" },
  { name: "动态校对", num: "36163", unit: "项" },
  { name: "报告推送", num: "16", unit: "项" },
]
const topList3 = [
  {
    num: 1,
    num1: 2,
    name: "/年",
    name1: "/周",
    title: "数据更新频率",
  },
  {
    num: "0.5 - 0.8",
    num1: 60,
    name: "万件",
    name1: "万件",
    title: "分析专利数量",
  },
]
const tableConfig = {
  headerBGC: "none",
  oddRowBGC: "none",
  evenRowBGC: "none",
  rowNum: 5,
  // header: ["通报内容"],
  // columnWidth: [280, 80, 80],
  align: ["left"],
  data: [
    // ["浙江吉成新材股份有限公司"],
    // ["宁波市长利自动化电器有限公司"],
    // ["浙江松信汽车空调有限公司"],
    // ["宁波市长利自动化电器有限公司"],
    // ["浙江松信汽车空调有限公司"],
    // ["宁波市长利自动化电器有限公司"],
    // ["浙江松信汽车空调有限公司"],
  ],
}
const radar_sj = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'数据名称',align:'center',width:'200'},
    {name:'数据源',align:'left'}
  ],
  list:[
    {0:'1',1:'国际标准',2:'国际标准化组织'},
    {0:'2',1:'国外标准',2:'国外标准化管理部门'},
    {0:'3',1:'国家标准',2:'国家标准化管理委员会、中华人民共和国住房和城乡建设部等'},
    {0:'4',1:'行业标准',2:'国家市场监督管理总局、国家工程建设标准化信息网、中华人民共和国工业和信息化部、国家药品监督管理局、中华人民共和国商业部、中华人民共和国农业农村部、中华人民共和国水利部等'},
    {0:'5',1:'地方标准',2:'浙江省品牌建设联合会'},
    {0:'6',1:'团体标准',2:'全国团体标准信息平台'},
  ]
}
const radar_zs = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'知识名称',align:'center'},
    {name:'类别',align:'center'}
  ],
  list:[
    {0:'1',1:'中华人民共和国标准化法',2:'法律'},
    {0:'2',1:'中华人民共和国产品质量法',2:'法律'},
    {0:'3',1:'中华人民共和国计量法',2:'法律'},
    {0:'4',1:'中华人民共和国标准化法实施条例',2:'行政法规'},
    {0:'5',1:'军用标准化管理办法',2:'行政法规'},
    {0:'6',1:'强制性国家标准管理办法',2:'部门规章'},
    {0:'7',1:'强制性产品认证管理规定',2:'部门规章'},
    {0:'8',1:'国家标准管理办法',2:'部门规章'},
    {0:'9',1:'行业标准管理办法',2:'部门规章'},
    {0:'10',1:'地方标准管理办法',2:'部门规章'},
    {0:'11',1:'团体标准管理规定',2:'部门规章'},
    {0:'12',1:'企业标准化管理办法',2:'部门规章'},
    {0:'13',1:'采用国际标准管理办法',2:'部门规章'},
    {0:'14',1:'浙江省标准化条例',2:'地方法规'},
    {0:'15',1:'浙江省地方标准管理办法',2:'地方法规'},
    {0:'16',1:'浙江省地方标准管理办法实施细则',2:'地方法规'},
    {0:'17',1:'国家标准化发展纲要',2:'政策文件'},
    {0:'18',1:'“十四五”推动高质量发展的国家标准体系建设规划',2:'政策文件'},
    {0:'19',1:'国务院关于印发深化标准化工作改革方案的通知',2:'政策文件'},
    {0:'20',1:'关于2021年国家级检验检测机构能力验证结果的通告',2:'政策文件'},
    {0:'21',1:'国家市场监管总局办公厅关于印发团体标准、企业标准随机抽查工作指引的通知',2:'政策文件'},
    {0:'22',1:'国务院办公厅关于印发强制性标准整合精简工作方案的通知',2:'政策文件'},
    {0:'23',1:'中共浙江省委浙江省人民政府关于贯彻落实《国家标准化发展纲要》的实施意见',2:'政策文件'},
    
  ]
}
const radar_al = {
  text:'案例类别一  企业标准监督抽查报告',
  title:[
    {name:'企业名称',align:'center',width:'300'},
    {name:'标准号',align:'center',width:'300'},
    {name:'引用标准存在问题情况',align:'left',}
  ],
  list:[
    {0:'杭州恒力纺织有限公司',1:'吸湿速干耐磨混纺纱Q/HLFZ 03-2020',2:'1、引用标准GB/T 9996-2008编号有误，正确为GB/T 9996.1-2008，请确认；2、引用标准FZ/T 10007-2008、FZ/T 10008-2009的最新现行版分别为FZ/T 10007-2018、FZ/T 10008-2018；3、第5.7条中的引用标准GB/T 29121.1-2009编号有误，正确为GB 2912.1-2009，请确认。'},
    {0:'杭州蓝妮生物科技有限公司',1:'蓝妮牌84消毒液Q/NTY 007-2020',2:'引用标准GB/T 191-2000、JJF 1070-2000的最新现行版分别为GB/T 191-2008、JJF 1070-2005。'},
    {0:'杭州梦成塑料包装有限公司',1:'食品包装用塑料桶（罐）Q/HMC001-2016',2:'引用标准GB/T 2828.1-2003、GB 9687、GB 9691的最新现行版分别为GB/T 2828.1-2012、GB 4806.7、GB 4806.6。'},
    {0:'杭州盘古自动化系统有限公司',1:'热分析温度控制器Q/HZPG 005-2020',2:'引用标准JB/T 9329无最新现行版。'},
    {0:'杭州三园茶业有限公司',1:'红茶GB/T13738.2-2017',2:'所执行的标准GB/T13738.2-2017《红茶》名称有误，正确为GB/T 13738.2-2017《红茶 第2部分：工夫红茶》。'},
  ]
}
const radar_al1 = {
  text:'案例类别二  标准有效性确认报告',
  title:[
    {name:'委托单位',align:'center'},
    {name:'报告编号',align:'center',width:'300'},
    {name:'确认标准数量',align:'center',}
  ],
  list:[
    {0:'衢州市交科工程检测有限公司',1:'QR20220093',2:'153'},
    {0:'宁波正信检测科技有限公司',1:'QR20220094',2:'1'},
    {0:'新昌县兴信建设工程检测中心有限公司',1:'QR20220095',2:'163'},
    {0:'浙江钢信检测技术有限公司',1:'QR20220096',2:'50'},
    {0:'浙江千麦司法鉴定中心嘉兴（新联）所',1:'QR20220097',2:'13'},
  ]
} 
const radar_al2 = {
  text:'案例类别三  标准比对报告',
  title:[
    {name:'报告名称',align:'center'},
    {name:'相关标准',align:'center'},
    {name:'报告录入时间',align:'center',}
  ],
  list:[
    {0:'GB/T 22479《花椒籽油》新旧标准比对',1:'GB/T 22479-2022;    GB/T 22479-2008',2:'2022/4/26'},
    {0:'GB/T 15854《食物搅拌器》新旧标准比对',1:'GB/T 15854-2021;    GB/T 15854-2008',2:'2022/1/26'},
    {0:'GB/T 10457《食品用塑料自粘保鲜膜质量通则》新旧标准比对',1:'GB/T 10457-2021；GB/T 10457-2009',2:'2021/12/30'},
    {0:'GB/T 20096《轮滑鞋》新旧标准比对',1:'GB/T 20096-2021；GB/T 20096-2006',2:'2021/11/26'},
    {0:'GB/T 28011《鞋类勾心》新旧标准比对',1:'GB/T 28011-2021；GB/T 28011-2011',2:'2021/10/29'},

  ]
}
const radar_gz = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'规则名称',align:'center',width:'400'},
    {name:'规则描述',align:'left',}
  ],
  list:[
    {0:'1',1:'标准数据清洗规则',2:'主要包括缺失值清洗、格式内容清洗、逻辑错误清洗、非需求数据清洗和标准关联关系验证。针对全面归集标准数据，进行审查和校验，目的在于删除重复信息（例如重复的企业标准、企业信息等），修正和补全信息（例如日期的格式错误、标准的编号错误、标准的年份号错误等），去除不需要用到的数据字段，最后将留存的数据进行关联性验证，将涉变标准数据与监管机构、企业和检测机构关联起来。'},
    {0:'2',1:'强制性标准判定规则',2:'标准编号不含“/T”的强制执行的标准。'},
    {0:'3',1:'推荐性标准判定规则',2:'标准编号含“/T”的推荐执行的标准。'},
    {0:'4',1:'标准效力转变规则',2:'标准强制性程度由强制性转变为推荐性，或由推荐性转变为强制性。'},
    {0:'5',1:'标准发布规则',2:'发布日期与当前日期的时间差小于等于30天，属于近一个月新发布标准。'},
    {0:'6',1:'标准作废规则',2:'作废日期与当前日期的时间差小于等于30天，属于近一个月作废标准。'},
    {0:'7',1:'适用范围识别规则',2:'标准文本“范围”章节中“本标准适用于”字段之后的文字为标准的适用范围，识别具有替代关系的两个标准适用范围的变化。'},
    {0:'8',1:'术语定义识别规则',2:'标准文本“术语和定义”章节中的子层级为标准的术语和定义，识别具有替代关系的两个标准术语和定义的变化。'},
    {0:'9',1:'技术指标识别规则',2:'以指标名称为关键词提取技术指标要求，识别具有替代关系的两个标准技术指标的变化。'},
    {0:'10',1:'检验方法识别规则',2:'以方法名称为关键词提取检验方法要求，识别具有替代关系的两个标准检验方法的变化。'},
  ]
}	

const radar_gs = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'公式名称',align:'center',width:'300'},
    {name:'具体公式',align:'left',}
  ],
  list:[
    {0:'1',1:'数据全位扫描公式',2:`<img src="${require('../../assets/img/bzld_gs1.png')}">`},
    {0:'2',1:'关系图卷积网络公式',2:`<img src="${require('../../assets/img/bzld_gs2.png')}">`},
    {0:'3',1:'雷达智联探知公式',2:`<img src="${require('../../assets/img/bzld_gs3.png')}">`},
  ]
}
const radar_sf = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'算法名称',align:'center',width:'400'},
    {name:'算法描述',align:'left',}
  ],
  list:[
    {0:'1',1:'命名实体识别',2:'指识别文本中具有特定意义的实体，主要包括规范性引用文件、机构名、专有名词等。'},
    {0:'2',1:'关系抽取',2:'从自然语言文本中，抽取出特定的事件或事实信息，帮助我们将海量内容自动分类、提取和重构。这些信息通常包括实体、关系、事件。'},
    {0:'3',1:'实体对齐算法',2:'旨在判断两个或者多个不同信息来源的实体是否为指向z真实世界中同一个对象。如果多个实体表征同一个对象，则在这些实体之间构建对齐关系，同时对实体包含的信息进行融合和聚集。'},
    {0:'4',1:'特征匹配',2:'将从影像中提取的特征作为共轭实体,而将所提特征属性或描述参数(实际上是特征的特征，也可以认为是影像的特征)作为匹配实体，通过计算匹配实体之间的相似性测度以实现共轭实体配准的影像匹配方法。'},
    {0:'5',1:'聚类分析',2:'指将物理或抽象对象的集合分组为由类似的对象组成的多个类的分析过程。'},
    {0:'6',1:'特征抽取',2:'指的是使用计算机提取图像信息，决定每个图像的点是否属于一个图像特征。特征提取的结果是把图像上的点分为不同的子集，这些子集往往属于孤立的点、连续的曲线或者连续的区域。'},
    {0:'7',1:'特征计算',2:'通过计算的特征多项式，求出特征方程的全部根，即得全部特征值，对于的每一个特征值，求出齐次线性方程组基础解系，获得属于特征值的全部特征向量。'},
    {0:'8',1:'分类器训练',2:'在标记好类别的训练数据基础上判断一个新的观察样本所属的类别，对已有的数据库用算法来进行分类。根据训练样本的特征，使用公式来对未知样本划分。'},
  ]
}
const radar_gj = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'工具名称',align:'center',width:'400'},
    {name:'描述',align:'left',}
  ],
  list:[
    {0:'1',1:'图文识别',2:'基于OCR技术实现将图片中的文字识别提取出来的软件，具有识别中文、英文、日文等多种文字的能力。'},
    {0:'2',1:'前端埋点',2:'针对特定用户行为或事件进行捕获、处理和发送的相关技术及其实施过程。 埋点的技术实质，是先监听软件应用运行过程中的事件，当需要关注的事件发生时进行判断和捕获。'},
    {0:'3',1:'搜索引擎',2:'搜索引擎是指根据一定的策略、运用特定的计算机程序从互联网上采集信息，在对信息进行组织和处理后，为用户提供检索服务，将检索的相关信息展示给用户的工具。'},
    {0:'4',1:'GIS地图',2:'在计算机硬、软件系统支持下，对整个或部分地球表层（包括大气层）空间中的有关地理分布数据进行采集、储存、管理、运算、分析、显示和描述的技术系统。'},
    {0:'5',1:'网络爬虫',2:'一种按照一定的规则，自动地抓取万维网信息的程序或者脚本。'},
    {0:'6',1:'语义分析',2:'通过建立有效的模型和系统，实现在各个语言单位（包括词汇、句子和篇章等）的自动语义分析，从而实现理解整个文本表达的真实语义。'},
    {0:'7',1:'关系抽取',2:'基于卷积神经网络的关系抽取方法接受一个特定的向量矩阵作为输入，使用其他特征进行语义信息汇总，进行抽取差异标准。'},
    {0:'8',1:'人机协同',2:'智能化终端设备收集数据，同时也是人机交互的入口，云端大脑是整个数据的汇集、分析、提炼的中枢，当数据大脑经过分析，形成相关的服务后，通过嵌入式的模块，实现人机协同在各个场景落地。'},
    
  ]
}	
const radar_mx = {
  title:[
    {name:'序号',align:'center',width:'100'},
    {name:'模型名称',align:'center',width:'400'},
    {name:'模型描述',align:'left',}
  ],
  list:[
    {0:'1',1:'数据全位扫描模型 ',2:'针对标准公告信息提取标准状态及关联信息，扫描全库国家、行业、地方标准的关联关系，确定状态变更标准。'},
    {0:'2',1:'标准关联图谱构建模型',2:'通过R-GCN技术（关系图卷积网络）计算标准要素关联关系并构建图谱。'},
    {0:'3',1:'雷达智联探知模型',2:'对知识图谱中的关联关系进行解构，通过语义分析，对差异数据进行提取及比对。'},    
  ]
}	
export {
  ulist,
  tableConfig,
  topList1,
  topList3,
  radar_sj,
  radar_zs,
  radar_al,
  radar_al1,
  radar_al2,
  radar_gs,
  radar_gz,
  radar_sf,
  radar_gj,
  radar_mx
}
