<template>
  <div>
    <Headers
      title="温州市服装标准化创新服务平台"
      :show="false"
      :returnShow="true"
    ></Headers>
    <div class="content">
      <div class="dp-l">
        <div class="dpl1">
          <div class="dp-title" style="position: relative">
            服装产业数据扫描
            <span
              style="
                font-size: 18px;
                position: absolute;
                right: 80px;
                top: 5px;
                cursor: pointer;
              "
              @click="openu('http://dzs.m.tuobao.com/#/')"
              >标准电子书</span
            >
            <img
              src="@/assets/img/arrow.png"
              alt=""
              style="
                width: 30px;
                position: absolute;
                right: 35px;
                top: 10px;
                cursor: pointer;
              "
              @click="openu('http://dzs.m.tuobao.com/#/')"
            />
            <!-- <span class="hrefs dp-a" @click="newopen('r-sjqwsm')"></span> -->
          </div>
          <div class="dplc" v-if="IndexSum">
            <!-- <div class="dplc-s"> -->
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=GJ')"
              >国际标准 <em>{{ IndexSum.StdINTL || 0 }}条</em></span
            >
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=GW')"
              >国外标准 <em>{{ IndexSum.StdCO || 0 }}条</em></span
            >
            <!-- </div> -->
            <!-- <div class="dplc-s"> -->
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=GB')"
              >国家标准 <em>{{ IndexSum.StdCN || 0 }}条</em></span
            >
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=HY')"
              >行业标准 <em>{{ IndexSum.StdQT || 0 }}条</em></span
            >
            <!-- </div> -->
            <!-- <div class="dplc-s"> -->
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=DF')"
              >地方标准 <em>{{ IndexSum.StdDB || 0 }}条</em></span
            >
            <span class="dplc-sl dp-a" @click="openu('/catalog?type=TT')"
              >团体标准 <em>{{ IndexSum.StdTT || 0 }}条</em></span
            >
            <!-- </div> -->
            <!-- <div class="dplc-p">{{DataSource}}</div> -->
          </div>
        </div>
        <div class="dpl2">
          <div
            class="dp-title"
            style="cursor: pointer"
            @click="openu(`/tpList`)"
          >
            温州市服装产业链图谱
            <!-- <span class="hrefs dp-a" @click="newopen('r-bztpgj')"></span> -->
          </div>
          <div class="dplc">
            <img class="radar11" src="../../assets/imgs/wz5.png" alt="" />
            <div
              :class="['tpList', `tpList${index + 1}`]"
              @click="openu(`/tpList?type=${item}`)"
              v-for="(item, index) in tpList"
              :key="item"
            ></div>

            <!-- <img class="radar4" src="../../assets/imgs/radar4.png" alt=""> -->
          </div>
        </div>
        <div class="dpl3">
          <div class="dp-title">
            <span class="dp-a" @click="openu('/std')">标准智联探知</span>
            <!-- <span class="hrefs dp-a" @click="newopen('r-ldzltz')"></span> -->
          </div>
          <div class="dplc dplc2" v-if="IndexSLStdCount">
            <div class="dplc-l">
              <span class="dplc-lt">涉联标准</span>
              <div class="dplc-b dplc-b1">
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  替代关系</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "废止作废").length >
                    0
                      ? IndexSLStdCount.filter((e) => e.name == "替代关系")[0].c
                      : 0
                  }}组</span
                >
              </div>
              <div class="dplc-b dplc-b1">
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  被引用关系</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "废止作废").length >
                    0
                      ? IndexSLStdCount.filter((e) => e.name == "引用关系")[0].c
                      : 0
                  }}组</span
                >
              </div>
              <div class="dplc-b dplc-b1">
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  废止作废</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "废止作废").length >
                    0
                      ? IndexSLStdCount.filter((e) => e.name == "废止作废")[0].c
                      : 0
                  }}项</span
                >
              </div>
            </div>
            <div class="dplc-l">
              <span class="dplc-lt">涉变数据</span>
              <div
                class="dplc-b dplc-b1 cursor"
                @click="openu('/std?type=etype1')"
              >
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  范围术语</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "范围术语")[0].c ||
                    0
                  }}项</span
                >
              </div>
              <div
                class="dplc-b dplc-b1 cursor"
                @click="openu('/std?type=etype2')"
              >
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  重要指标</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "重要指标")[0].c ||
                    0
                  }}项</span
                >
              </div>
              <div
                class="dplc-b dplc-b1 cursor"
                @click="openu('/std?type=etype3')"
              >
                <span class="dplc-bl"
                  ><img src="../../assets/imgs/radar15.png" alt="" />
                  检测方法</span
                >
                <span class="dplc-br"
                  >{{
                    IndexSLStdCount.filter((e) => e.name == "检测方法")[0].c ||
                    0
                  }}项</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dp-c">
        <div
          class="table-center-top"
          style="margin-bottom: 10px; height: 110px"
        >
          <div class="left-title">核心业务</div>
          <!-- <div class="left-title dp-a" @click="newopen('radar-hxyw')">核心业务</div> -->
          <div class="right-content">
            <div class="tab-bg" v-for="(item, index) in topList1" :key="index">
              <div class="tab-bg-text">{{ item.name }}</div>
              <div class="tab-bg-num">
                {{ item.num }}
                <!-- <span class="tab-bg-unit">{{item.unit}}</span> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="table-center-top" style="margin-bottom:10px;height: 110px;">
          <div class="left-title">要素矩阵</div>
          <div class="right-content">
            <div class="tab-bg2 dp-a" v-for="(item,index) in ulist" :key="index" @click="open(item.url)">
              <span class="span1">{{item.title}}</span>
              <span class="span2">{{item.value}}</span>
              <span class="span3">{{item.unit}}</span>
            </div>
          </div>
        </div> -->
        <div
          class="table-center-top"
          style="margin-bottom: 10px; height: 130px"
        >
          <div class="left-title">智能化成效</div>
          <div class="right-content" v-if="indexComOrgCount">
            <span class="span3"
              >标准动态变化报告精准推送至监管部门<em
                style="cursor: pointer"
                @click="openu('/dept')"
                >{{ indexComOrgCount.jgcount }}</em
              >
              份， 企业
              <em style="cursor: pointer" @click="openu('/company')">{{
                indexComOrgCount.comcount
              }}</em>
              份， 第三方机构
              <em style="cursor: pointer" @click="openu('/org')">{{
                indexComOrgCount.orgcount
              }}</em>
              份， 推送报告总数
              <em>{{ indexComOrgCount.bgcount }}</em> 份。</span
            >
            <!-- <span class="span3">标准动态变化报告精准推送至企业 <em>{{indexComOrgCount.comcount}}</em> 家、第三方机构 <em>{{indexComOrgCount.orgcount}}</em> 家，推送报告总数 <em>{{indexComOrgCount.bgcount}}</em> 份。</span> -->
            <!-- <div class="tab-bg3" v-for="(item,index) in topList3" :key="index">
              {{item.title}}：先前<span class="span2">{{item.num}}</span><span>{{item.name}}</span>&nbsp;&nbsp;&nbsp;&nbsp;现在<span class="span2">{{item.num1}}</span>{{item.name1}}
            </div> -->
          </div>
        </div>
        <div id="map" style="width: 100%; height: 800px"></div>
        <!-- <ul class="dp-ul">
          <li class="dp-li" v-for="(item, index) in ulist" :key="index" @click="open(item.url)">
            <span>{{ item.title }}</span>
            <span>{{ item.value }}</span>
          </li>
        </ul> -->
        <!-- <iframe class="dp-iframe" src="https://suiji.zjamr.zj.gov.cn/images/vscodescreensmall/#/external/zstp?source=fxyj-bzdtld" frameborder="0"></iframe> -->
        <!-- <div class="byc-s1" v-if="mothIndexCount">
          <span style="margin-bottom:40px">
            近一个月，构建标准图谱 <em>{{Number(mothIndexCount.sl)+Number(mothIndexCount.sb)}}</em> 个，雷达探知涉联标准 <em>{{mothIndexCount.sl}}</em> 项，涉变数据 <em>{{mothIndexCount.sb}}</em> 项。
          </span>
        </div> -->
        <!-- <img class="byc1" src="../../assets/img/byc1.png" alt="" /> -->
      </div>
      <div class="dp-r">
        <div class="dpr">
          <div class="dp-title">
            <span>服务矩阵</span>
          </div>
          <div class="dprc">
            <div class="dpr-title">
              <span class="dp-a" @click="openu('/dept')">监管部门</span>
              <!-- <span class="hrefs dp-a" @click="openu('https://bz.zjamr.zj.gov.cn/001155.html?dnFromType=1')"></span> -->
            </div>
            <div class="dpr1" v-if="deptIndexCount">
              <div class="dpr1-l">
                <span>{{ deptIndexCount.drcount || 0 }}次</span>
              </div>
              <div class="dpr1-l dpr1-r">
                <span>{{ deptIndexCount.dycount || 0 }}次</span>
              </div>
            </div>
            <div class="dpr2" v-if="deptIndexCount">
              <div
                class="dpr2-l"
                style="cursor: pointer"
                @click="openu('/dept?type=BZGL')"
              >
                <span class="dpr2-lt">标准管理</span>
                <span class="dpr2-lb">{{ deptIndexCount.bzcount || 0 }}次</span>
              </div>
              <img
                class="dpr2-img"
                src="../../assets/imgs/radar18.png"
                alt=""
              />
              <div
                class="dpr2-l"
                style="cursor: pointer"
                @click="openu('/dept?type=HGPD')"
              >
                <span class="dpr2-lt">合格评定</span>
                <span class="dpr2-lb">{{ deptIndexCount.hgcount || 0 }}次</span>
              </div>
              <img
                class="dpr2-img"
                src="../../assets/imgs/radar18.png"
                alt=""
              />
              <div
                class="dpr2-l"
                style="cursor: pointer"
                @click="openu('/dept?type=ZLJG')"
              >
                <span class="dpr2-lt">质量监管</span>
                <span class="dpr2-lb">{{ deptIndexCount.zlcount || 0 }}次</span>
              </div>
              <!-- <img class="dpr2-img" src="../../assets/imgs/radar18.png" alt="">
              <div class="dpr2-l">
                <span class="dpr2-lt">食品监管</span>
                <span class="dpr2-lb">{{deptIndexCount.spcount||0}}次</span>
              </div> -->
            </div>
            <div class="dpr-title">
              <span class="dp-a" @click="openu('/company')">企业</span>
              <!-- <span class="hrefs dp-a" @click="openu('https://bz.zjamr.zj.gov.cn/001155.html?dnFromType=3')"></span> -->
            </div>
            <div class="dpr3" v-if="companyIndexCount">
              <div class="dpr3-l">
                <img src="../../assets/imgs/radar8.png" alt="" />
                <div class="dpr3-s">
                  <span class="dpr3-sl">今日推送</span>
                  <span class="dpr3-sr"
                    >{{ companyIndexCount.drcount || 0 }}份</span
                  >
                </div>
              </div>
              <div class="dpr3-l">
                <img
                  style="height: 46px"
                  src="../../assets/imgs/radar9.png"
                  alt=""
                />
                <div class="dpr3-s">
                  <span class="dpr3-sl">本月推送</span>
                  <span class="dpr3-sr"
                    >{{ companyIndexCount.dycount || 0 }}份</span
                  >
                </div>
              </div>
              <div
                class="dpr3-r"
                style="cursor: pointer"
                @click="openu('/company?type=adv1')"
              >
                <span class="dpr3-sl">建议提升生产工艺</span>
                <span class="dpr3-sr">{{ companyIndexCount.scgy || 0 }}</span>
              </div>
              <div
                class="dpr3-r"
                style="margin-bottom: 0; cursor: pointer"
                @click="openu('/company?type=adv2')"
              >
                <span class="dpr3-sl">建议更新使用范围</span>
                <span class="dpr3-sr">{{ companyIndexCount.syfw || 0 }}</span>
              </div>
              <div
                class="dpr3-r"
                style="cursor: pointer"
                @click="openu('/company?type=adv3')"
              >
                <span class="dpr3-sl">建议验证产品质量</span>
                <span class="dpr3-sr">{{ companyIndexCount.cpzl || 0 }}</span>
              </div>
              <div
                class="dpr3-r"
                style="margin-bottom: 0; cursor: pointer"
                @click="openu('/company?type=adv4')"
              >
                <span class="dpr3-sl">建议更新企标自我声明</span>
                <span class="dpr3-sr">{{ companyIndexCount.zwsm || 0 }}</span>
              </div>
            </div>
            <!-- <div class="chart" id="chart"></div> -->
            <div class="dpr-title">
              <span class="dp-a" @click="openu('/org')">第三方机构</span>
              <!-- <span class="hrefs dp-a" @click="openu('https://bz.zjamr.zj.gov.cn/001155.html?dnFromType=4')"></span> -->
            </div>
            <div class="dpr4">
              <div class="dpr4-l" v-if="orgIndexCount">
                <div class="dpr4-lc dp-a" @click="openu('/org?type=NL')">
                  <!-- <img src="../../assets/imgs/radar8.png" alt=""> -->
                  <div class="dpr3-s">
                    <span class="dpr3-sl">能力取消</span>
                    <span class="dpr3-sr"
                      >{{ orgIndexCount.nlcount || 0 }}项</span
                    >
                  </div>
                </div>
                <div class="dpr4-lc dp-a" @click="openu('/org?type=ZZ')">
                  <!-- <img src="../../assets/imgs/radar9.png" alt=""> -->
                  <div class="dpr3-s">
                    <span class="dpr3-sl">资质变更</span>
                    <span class="dpr3-sr"
                      >{{ orgIndexCount.zzcount || 0 }}项</span
                    >
                  </div>
                </div>
              </div>
              <div class="chart1">
                <Tablescroll
                  :config="tableConfig"
                  ref="listOrgTop50"
                  refName="listOrgTop50"
                ></Tablescroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="tcbg"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      top="5vh"
      width="80%"
    >
      <div class="tcimg">
        <UlistTab :type="imgsrc"></UlistTab>
        <!-- <iframe :src="imgsrc" frameborder="0"></iframe> -->
        <!-- <img :src="imgsrc" alt="" /> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { world } from "../utils/worldZH"
import router from "../../router";
import Headers from "@/components/headers";
import Tablescroll from "@/components/tablescroll";
import UlistTab from "@/components/ulistTab";

// import Histogram from "@/components/histogram";
// import Echartsline from "@/components/line";
// import Radar from "@/components/radar";
import { ulist, tableConfig, topList1, topList3 } from "../utils/radardata";
export default {
  name: "ldindex",
  components: {
    Headers,
    Tablescroll,
    UlistTab,
    // Histogram,
    // Echartsline,
    // Radar,
  },
  data() {
    return {
      imgsrc: "",
      dialogVisible: false,
      IndexSum: null,
      DataSourceTop10: [],
      IndexSLStdCount: null,
      deptIndexCount: null,
      companyIndexCount: null,
      orgIndexCount: null,
      mothIndexCount: null,
      indexComOrgCount: null,
      DataSource: "",
      SourceTop: 0,
      monthlyNotify: {
        TongBao: 3634,
        TongBao1: 11310,
      },
      influenceCom: {
        qiye: 11188,
        jigou: 122,
        cyname: "玩具、纺织、食品和汽摩配",
        cycount: 12,
        dfbz: 21,
        ttbz: 49,
      },
      ulist: [...ulist],
      topList1: [...topList1],
      topList3: [...topList3],
      tableConfig: { ...tableConfig },
      mapdata: {},
      tooltip: [
        {
          lng: "120.153576",
          lat: "30.287459",
          name: "鹿城区",
          info: '鹿城区</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>3个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>3份</strong></font>',
        },
        {
          lng: "121.549792",
          lat: "29.868388",
          name: "瓯海区",
          info: '瓯海区</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>1个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>1份</strong></font>',
        },
        {
          lng: "120.846891",
          lat: "28.280824",
          name: "洞头县",
          info: '洞头县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>1个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>4份</strong></font>',
        },
        {
          lng: "120.750865",
          lat: "30.762653",
          name: "永嘉县",
          info: '永嘉县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>4份</strong></font>',
        },
        {
          lng: "120.102398",
          lat: "30.867198",
          name: "平阳县",
          info: '平阳县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>4份</strong></font>',
        },
        {
          lng: "120.102398",
          lat: "30.867198",
          name: "苍南县",
          info: '苍南县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>4份</strong></font>',
        },
        {
          lng: "120.582112",
          lat: "29.997117",
          name: "文成县",
          info: '文成县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>3份</strong></font>',
        },
        {
          lng: "119.649506",
          lat: "29.089524",
          name: "泰顺县",
          info: '泰顺县</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>1个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>3份</strong></font>',
        },
        {
          lng: "118.87263",
          lat: "28.941708",
          name: "瑞安市",
          info: '瑞安市</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>1份</strong></font>',
        },
        {
          lng: "122.106863",
          lat: "30.016028",
          name: "乐清市",
          info: '乐清市</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>0份</strong></font>',
        },
        {
          lng: "121.428599",
          lat: "28.661378",
          name: "龙湾区",
          info: '龙湾区</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>0家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>0个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>2份</strong></font>',
        },
      ],
      areaCode: "330300",
      oldAreaCode: "330300",
      mapjson: {},
      title: "浙江",
      oldAreaName: "浙江",
      childNum: null,
      tpList: [
        "服装原材料",
        "纽扣拉链等其他材料",
        "纺织机械",
        "衬衫",
        "风衣",
        "服装定制",
        "家用纺织品",
        "夹克衫",
        "连衣裙",
        "棉服",
        "男装",
        "牛仔裤",
        "女西服、西裤",
        "女装",
        "童装",
        "西服、西裤",
        "休闲服装",
        "学生服",
        "羊毛制品",
        "婴幼儿服装",
        "羽绒服",
        "运动服装",
        "针织内衣",
        "职业装",
        "大象城",
        "线上平台",
        "第三方检测机构",
        "质检设备",
      ],
      jgcount:0
    };
  },
  computed: {
    show() {
      return this.areaCode !== sessionStorage.getItem("areaCode");
    },

  },
  watch: {
    areaCode: {
      handler(val, oldVal) {
        if (val != oldVal && val) {
          this.getMapJson();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.title = "温州市服装标准化创新服务平台";
    this.$nextTick(async () => {
      this.getIndexSum();
      this.getDataSourceTop10();
      this.getcompanyIndexCount();
      this.getorgIndexCount();
      this.getlistOrgTop50();
      // this.getcompanyIndexEchart()
      this.getmothIndexCount();
      this.getIndexSLStdCount();
      this.getdeptIndexCount();
      // this.getpie()

      // this.areaCode = sessionStorage.getItem("areaCode")
      // this.oldAreaCode = sessionStorage.getItem("areaCode")
      // this.title = sessionStorage.getItem("areaName")
      // this.oldAreaName = sessionStorage.getItem("areaName")
      this.getMapJson();
    });
    setInterval(() => {
      if (this.SourceTop < this.DataSourceTop10.length - 1) {
        this.SourceTop++;
      } else {
        this.SourceTop = 0;
      }
      this.DataSource = this.DataSourceTop10[this.SourceTop].WebName;
    }, 5000);
  },
  methods: {
    newopen(url) {
      let imgUrl = router.resolve({ path: "./jpgimage", query: { url: url } });
      window.open(imgUrl.href);
    },
    open(url) {
      // this.imgsrc = require("../../assets/img/" + url + ".png")
      this.imgsrc = url;
      console.log(this.imgsrc);
      this.dialogVisible = true;
    },
    openu(url) {
      window.open(url);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 数据全位扫描标准数和中间八大模块数
    async getIndexSum() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "getIndexSum", single: true },
      });
      this.IndexSum = res.data.data;
      this.ulist[0].value = this.IndexSum.SJ;
      this.ulist[1].value = this.IndexSum.ZS;
      this.ulist[2].value = this.IndexSum.AL;
      this.ulist[3].value = this.IndexSum.GZ;
      this.ulist[4].value = this.IndexSum.GS;
      this.ulist[5].value = this.IndexSum.SF;
      this.ulist[6].value = this.IndexSum.GJ;
      this.ulist[7].value = this.IndexSum.MX;
    },
    // 智能化成效
    async getindexComOrgCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "indexComOrgCount", single: true },
      });
      console.log(res);
      // if (res.data.data && res.data.data.length > 0) {

      this.indexComOrgCount = res.data.data;
      this.indexComOrgCount.jgcount = this.jgcount;
      console.log( this.jgcount)
      this.topList1[1].num =
        this.IndexSLStdCount.filter((e) => e.name == "替代关系")[0].c +
        this.IndexSLStdCount.filter((e) => e.name == "引用关系")[0].c +
        this.IndexSLStdCount.filter((e) => e.name == "废止作废")[0].c;
      this.topList1[2].num =
        this.IndexSLStdCount.filter((e) => e.name == "范围术语")[0].c +
        this.IndexSLStdCount.filter((e) => e.name == "重要指标")[0].c +
        this.IndexSLStdCount.filter((e) => e.name == "检测方法")[0].c;
      this.topList1[3].num = res.data.data.bgcount;

      this.topList1[0].num =
        this.topList1[1].num +
        this.indexComOrgCount.comcount +
        this.indexComOrgCount.orgcount;

      // }
    },
    // 数据全位扫描中间企业
    async getDataSourceTop10() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "getDataSourceTop10" },
      });
      if (res.data.data && res.data.data.length > 0) {
        this.DataSourceTop10 = res.data.data;
        this.DataSource = this.DataSourceTop10[0].WebName;
      }
    },
    // 雷达智联探知
    async getIndexSLStdCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "getIndexSLStdCount" },
      });
      this.IndexSLStdCount = res.data.data;
    },
    // 监管部门
    async getdeptIndexCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "deptIndexCount", single: true },
      });
      this.deptIndexCount = res.data.data;
      this.jgcount =
        Number(this.deptIndexCount.bzcount) +
        Number(this.deptIndexCount.hgcount) +
        Number(this.deptIndexCount.zlcount) +
        Number(this.deptIndexCount.spcount);
      this.getindexComOrgCount();

    },
    // 企业
    async getcompanyIndexCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "companyIndexCount", single: true },
      });
      this.companyIndexCount = res.data.data;
    },
    // 第三方机构
    async getorgIndexCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "orgIndexCount", single: true },
      });
      this.orgIndexCount = res.data.data;
    },
    // 第三方机构列表
    async getlistOrgTop50() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "listOrgTop50" },
      });
      console.log(res);
      this.tableConfig.data = [];
      res.data.data.forEach((item) => {
        this.tableConfig.data.push([
          "<a target='_blank' class='dp-a' style='color:#fff' href='/orgldbg?id=" +
            item["PKID"] +
            "'>" +
            item["OrgName"] +
            "</a>",
        ]);
      });
      this.$nextTick(() => {
        this.$set(this.tableConfig, "data", this.tableConfig.data);
        this.$refs["listOrgTop50"].updateR();
      });
    },
    // 中下
    async getmothIndexCount() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "mothIndexCount", single: true },
      });
      console.log(res);
      this.mothIndexCount = res.data.data;
    },
    // 企业柱状图
    async getcompanyIndexEchart() {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "companyIndexEchart" },
      });
      console.log(res);
      this.drawbar(res.data.data);
    },
    drawbar(data) {
      console.log(data);
      let myChart = this.$echarts.init(document.getElementById("chart"));
      var option = (option = {
        // legend: {
        //   left: "center",
        //   show: true,
        //   textStyle: {
        //     color: "#fff",
        //     fontSize: 16,
        //   },
        // },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
          },
          axisLine: {
            //y轴
            show: true,
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            // interval: this.data.dataInfo.interval,
            // max: this.data.dataInfo.max,
            nameTextStyle: {
              color: "#fff",
              fontSize: 16,
            },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#fff",
                fontSize: 14,
              },
            },
            axisLine: {
              //y轴
              show: true,
            },
            axisTick: {
              //y轴刻度线
              show: false,
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        grid: {
          left: "0",
          right: "10px",
          top: "30px",
          bottom: 0,
          containLabel: true,
        },
        series: [
          {
            name: "",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: 16,
              },
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#2cf8d9" },
                { offset: 1, color: "#6b12cc" },
              ]),
            },
            data: [],
            type: "bar",
          },
        ],
      });
      data.forEach((e) => {
        option.xAxis.data.push(e.name);
        option.series[0].data.push({
          value: e.c || 4,
          name: e.name,
        });
      });
      myChart.setOption(option, true);
    },
    async getMapJson() {
      console.log(this.areaCode);
      const mapdata = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: { code: "IndexMapCount" },
      });
      console.log(mapdata.data.data);
      this.tooltip.forEach((e) => {
        mapdata.data.data.forEach((el) => {
          if (e.name == el.AreaName) {
            e.info = `${e.name}</strong></font><br/><font color="#ffffff">企业数:</font><font color="#1affd1"><strong>${el.cocount}家</strong></font><br/><font color="#ffffff">公开标准数:</font><font color="#1affd1"><strong>${el.comcount}个</strong></font><br/><font color="#ffffff">推送报告数:</font><font color="#1affd1"><strong>${el.stdcount}份</strong></font>`;
          }
        });
      });
      const res = await this.$axios.get(`./mapJson/${this.areaCode}.json`);
      res.data.features.map((item) => {
        item.properties.code = item.id || item.properties.code;
        return item;
      });
      console.log(res.data);
      this.mapjson = res.data;
      this.$nextTick(() => {
        this.map();
      });
    },
    map() {
      let mapset = this.$echarts.init(document.getElementById("map"));
      mapset.showLoading();
      var that = this;
      mapset.hideLoading();
      that.$echarts.registerMap("HK", this.mapjson);
      var option = {
        title: {},
        tooltip: {
          show: true,
          trigger: "item",
          enterable: true,
          padding: [30, 50],
          className: "hoverimage",
          position: function (pos) {
            return pos;
          },
          formatter: function (res) {
            var html = `<div style="
            position: absolute;
            background: rgba(0, 9, 56, 0.88);
            border-radius: 0px;
            box-shadow: 14px 14px 5px rgba(18, 35, 60, 0);
            "><div style="
                margin: 10px 20px 10px 20px;
                min-width: 250px;
                word-wrap: break-word;
                display: inline-block;
                text-align: left;·
                text-overflow: ellipsis;
                font-family: Microsoft Yahei;
                color: #FFFFFF;
                font-size: 18px;
                line-height: 1.7;">`;
            that.tooltip.forEach((item) => {
              if (item.name == res.name) {
                html += item.info;
              }
            });
            html += "</div></div>";
            return html;
          },
        },
        series: [
          {
            name: name + "分布图",
            type: "map",
            mapType: "HK", // 自定义扩展图表类型
            zoom: 1.2, //当前视角的缩放比例
            roam: false, //是否开启平游或缩放
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 1,
              max: 10,
            },
            selectedMode: false,
            aspectScale: 0.9,
            itemStyle: {
              normal: {
                label: {
                  show: true, //选中状态是否显示省份名称
                  color: "#fff",
                  fontSize: 14,
                  fontFamily: "宋体",
                },
                // shadowColor: "rgba(0, 0, 0, 0.8)",
                // shadowBlur: 100,
                areaColor: "#0273c4",
                borderWidth: 1,
                borderColor: "rgb(143, 230, 243)",
              },
              emphasis: {
                itemStyle: {
                  color: "#4eacfb", //选中状态的地图板块颜色
                },
              },
            },
            data: [],
          },
        ],
        visualMap: {
          show: false,
          min: 0,
          max: 3,
          inRange: {
            color: [
              "rgb(17,172,218)",
              "rgb(11,128,218)",
              "rgb(8,108,218)",
              "rgb(0,48,218)",
            ],
          },
        },
      };
      mapset.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.dp-chart {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/by1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpr-chart {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/by3.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dp-title {
  color: #fff;
  font-size: 25px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 40px;
  padding: 0 30px;
}

.large-charttitle.active {
  color: rgb(0, 239, 255);
}

.dp-l {
  width: 30%;
}

.dp-c {
  width: 35%;
}

.dp-r {
  width: 32%;
}

.dpc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
}

.dp-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dp-li {
  color: #fff;
  width: 24%;
  padding: 10px;
  background: url(../../assets/img/byc2.png) no-repeat center center;
  background-size: 100% 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.dp-li span {
  display: block;
  text-align: center;
  margin: 5px 0;
  font-size: 18px;
}

.dp-li span:last-child {
  color: #00ffde;
  font-weight: bold;
}

.dp-iframe {
  width: 100%;
  height: 590px;
}

/deep/ .row-item {
  width: 100%;
  background-image: url(../../assets/img/by7.png);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .header-item {
  text-align: center;
  color: #00ffde;
}

.tcimg {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/ysjz_bg.png");
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.tcimg img {
  width: 100%;
  height: 100%;
}

.dpl1 {
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
  background-image: url(../../assets/imgs/wz1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpl2 {
  width: 100%;
  height: 440px;
  margin-bottom: 10px;
  background-image: url(../../assets/imgs/wz2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpl3 {
  width: 100%;
  height: 285px;
  background-image: url(../../assets/imgs/wz3.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpr {
  width: 100%;
  height: 960px;
  background-image: url(../../assets/imgs/wz4.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dplc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

// .dplc1 {
//   background: url(../../assets/imgs/radar1.png) no-repeat center center;
// }
.dplc2 {
  display: flex;
  justify-content: space-between;
}

.dprc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
}

.dplc-s {
  display: flex;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  padding: 0 10px;
  margin-bottom: 10px;
}

.dplc-sl {
  color: #fff;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/imgs/wz6.png) no-repeat center center;
}

.dplc-sr {
  color: #fff;
}

.dplc-sl em {
  color: #00deff;
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
}

.dplc-sr em {
  color: #00ffde;
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
}

.radar4 {
  width: 100%;
}

.radar11 {
  width: 100%;
}

.dplc-l {
  width: 48%;
  height: 100%;
}

.dplc-lt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: url(../../assets/imgs/radar13.png) no-repeat center center;
  background-size: 100% 100%;
  color: #00deff;
  font-size: 20px;
  font-weight: bold;
}

.dplc-b {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: url(../../assets/imgs/radar14.png) no-repeat center center;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dplc-b1 {
  height: 60px;
}

.dplc-bl {
  width: 140px;
  color: #fff;
}

.dplc-bl img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.dplc-br {
  width: calc(100% - 140px);
  color: #00ffde;
  font-size: 24px;
}

.dplc-p {
  position: absolute;
  color: #00ffde;
  width: 100px;
  height: 100px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  padding: 0 10px;
  text-align: center;
}

.byc-s1 {
  width: 100%;
  height: 100px;
  background-image: url(../../assets/img/byc-s1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px 20px 20px 20px;
}

.byc-s1 span {
  display: block;
}

.byc-s1 span:first-child {
  color: #fff;
  font-size: 21px;
}

.byc-s1 span em {
  color: #ffac45;
  font-weight: bold;
  font-style: normal;
  font-size: 21px;
}

// .byc-s1 span:last-child {
//   color: #fff;
//   font-size: 21px;
// }
// .byc-s1 span:last-child em {
//   color: #00ffde;
//   font-weight: bold;
//   font-style: normal;
//   font-size: 21px;
// }
.dpr-title {
  width: 100%;
  height: 40px;
  background-image: url(../../assets/imgs/radar17.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

.dpr1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.dpr1-l {
  width: 48%;
  height: 112px;
  position: relative;
  background: url(../../assets/imgs/wz10.png) no-repeat center center;
}

.dpr1-r {
  width: 48%;
  height: 112px;
  background: url(../../assets/imgs/wz11.png) no-repeat center center;
}

.dpr1-l span {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #00ffde;
  width: 100px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.dpr2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dpr2-l {
  width: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dpr2-lt {
  color: #fff;
  font-size: 18px;
}

.dpr2-lb {
  color: #00ffde;
  font-size: 20px;
  font-weight: bold;
}

.dpr3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}

.dpr3-l {
  width: 48%;
  height: 90px;
  background: url(../../assets/imgs/wz9.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dpr3-s {
  width: 100px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-left: 10px;
}

.dpr3-sl {
  color: #fff;
}

.dpr3-sr {
  color: #00ffde;
  font-size: 20px;
  font-weight: bold;
}

.dpr3-r {
  width: 48%;
  height: 80px;
  background: url(../../assets/imgs/wz6.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.chart {
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
}

.dpr4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.dpr4-l {
  width: 200px;
}

.dpr4-lc {
  width: 100%;
  height: 110px;
  background: url(../../assets/imgs/wz9.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.chart1 {
  width: 350px;
  height: 250px;
}

.dp-a {
  cursor: pointer;
}

.table-center-top {
  display: flex;
  flex-wrap: wrap;
  background-image: url(../../assets/img/newTop.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  align-items: center;
  height: 120px;
}

.left-title {
  width: 60px;
  color: #1af1ff;
  background-color: rgba(0, 217, 255, 0.2);
  font-size: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 100%;
}

.right-content {
  height: 100%;
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 217, 255, 0.1);
  padding: 10px;
}

.tab-bg {
  width: 22%;
  text-align: center;
}

.tab-bg2 {
  width: 24%;
  background-image: url(../../assets/img/tab-bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px;
}

.span1 {
  color: #fff;
}

.span3 {
  color: #fff;
  font-size: 22px;
  line-height: 34px;
}

.span3 em {
  color: #00ffde;
  font-style: normal;
  font-size: 28px;
}

.span2 {
  color: #00ffde;
  font-size: 23px;
  margin: 0 5px;
}

.tab-bg3 {
  width: 100%;
  color: #fff;
  border-bottom: 1px solid rgba(0, 217, 255, 0.4);
  height: 52px;
}

.tab-bg3:last-child {
  border-bottom: 0;
}

.tab-bg-text {
  width: 100%;
  color: #fff;
  font-size: 16px;
  margin: 5px 0;
}

.tab-bg-unit {
  font-size: 16px;
  color: #1af1ff;
}

.tab-bg-num {
  color: #1af1ff;
  font-size: 24px;
  background-color: rgba(1, 8, 72, 0.35);
  border: 1px solid rgba(0, 217, 255, 0.4);
  border-radius: 4px;
  padding: 5px 10px;
}
</style>
<style>
.cursor {
  cursor: pointer;
}
.dp-see {
  width: 30px;
  height: 35px;
  background-image: url(../../assets/img/see.png);
  background-size: 30px 35px;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
}

.hrefs {
  height: 25px;
  width: 25px;
  background-image: url(../../assets/img/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  margin: 5px 10px;
  cursor: pointer;
}

.tpList {
  position: absolute;
  width: 25px;
  height: 90px;
  cursor: pointer;
}
/** 上游 **/
.tpList1 {
  left: 16px;
  top: 70px;
  width: 30px;
  height: 180px;
}
.tpList2 {
  left: 50px;
  top: 70px;
  width: 30px;
  height: 180px;
}
.tpList3 {
  left: 85px;
  top: 70px;
  width: 30px;
  height: 180px;
}
/** 中游 **/
.tpList4 {
  left: 148px;
  top: 65px;
}
.tpList5 {
  left: 180px;
  top: 65px;
}
.tpList6 {
  left: 213px;
  top: 65px;
}
.tpList7 {
  left: 245px;
  top: 65px;
}
.tpList8 {
  left: 278px;
  top: 65px;
}
.tpList9 {
  left: 310px;
  top: 65px;
}
.tpList10 {
  left: 344px;
  top: 65px;
}
.tpList11 {
  left: 148px;
  top: 165px;
}
.tpList12 {
  left: 180px;
  top: 165px;
}
.tpList13 {
  left: 213px;
  top: 165px;
}
.tpList14 {
  left: 245px;
  top: 165px;
}
.tpList15 {
  left: 278px;
  top: 165px;
}
.tpList16 {
  left: 310px;
  top: 165px;
}
.tpList17 {
  left: 344px;
  top: 165px;
}
.tpList18 {
  left: 148px;
  top: 275px;
}
.tpList19 {
  left: 180px;
  top: 275px;
}
.tpList20 {
  left: 213px;
  top: 275px;
}
.tpList21 {
  left: 245px;
  top: 275px;
}
.tpList22 {
  left: 278px;
  top: 275px;
}
.tpList23 {
  left: 310px;
  top: 275px;
}
.tpList24 {
  left: 344px;
  top: 275px;
}
/**下游 **/
.tpList25 {
  left: 400px;
  top: 140px;
  height: 130px;
}
.tpList26 {
  left: 435px;
  top: 140px;
  height: 130px;
}
.tpList27 {
  left: 480px;
  top: 140px;
  height: 130px;
}
.tpList28 {
  left: 513px;
  top: 140px;
  height: 130px;
}
</style>
