import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/radar/index.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Index',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: Index,
    },
    //温州市服装产业链图谱二级页面
    {
        path: '/tpList',
        name: 'tpList',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/tpList.vue')
    },
    {
        path: '/image',
        name: 'imageView',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/image.vue')
    },
    {
        path: '/jpgimage',
        name: 'jpgimageView',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/jpgimage.vue')
    },
    {
        path: '/table',
        name: 'table',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/table.vue')
    },
    {
        path: '/details',
        name: 'details',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/details.vue')
    },
    {
        path: '/std',
        name: 'Std',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/std.vue')
    },
    {
        path: '/dept',
        name: 'Dept',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/dept.vue')
    },
    {
        path: '/company',
        name: 'Company',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/company.vue')
    },
    {
        path: '/org',
        name: 'Org',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/org.vue')
    },
    {
        path: '/catalog',
        name: 'Catalog',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/catalog.vue')
    },
    {
        path: '/ldbg',
        name: 'Ldbg',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/ldbg.vue')
    },
    {
        path: '/catalogView',
        name: 'CatalogView',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/catalogView.vue')
    },
    {
        path: '/catalogQbView',
        name: 'CatalogQbView',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/catalogQbView.vue')
    },
    {
        path: '/orgldbg',
        name: 'Orgldbg',
        meta: {
            title: "第三方机构对策建议",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/orgldbg.vue')
    },
    {
        path: '/orgnlldbg',
        name: 'orgnlldbg',
        meta: {
            title: "温州市服装标准化创新服务平台",
        },
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/radar/orgnlldbg.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/dist',
    routes
})
router.afterEach(to => {
    document.title = to.meta.title
})
export default router