<template>
  <dv-scroll-board :config="config" :ref="refName" />
</template>
<script>
export default {
  name: "",
  props: {
    config: {
      type: Object,
    },
    refName: {
      type: String,
    },
  },
  watch: {
    config: {
      handler: function () {
        this.updateR()
      },
      deep: true
    }
  },
  data () {
    return {}
  },
  methods: {
    updateR () {
      this.$refs[this.refName].updateRows(this.config.data, 0)
    },
  },
};
</script>
<style scoped>
</style>