import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import router from './router'
import axios from 'axios'
import { Message, Image } from 'element-ui'
import ElementUI from 'element-ui'
import moment from 'moment'

Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
// Vue.prototype.$apiUrl = 'http://192.168.82.164:10037/api/'
// Vue.prototype.$apiUrl = 'http://10.56.55.227:800/api/'
Vue.prototype.$apiUrl = 'http://www.wzisps.org.cn/api/'

// Vue.prototype.$apiUrl = '/api/'
Vue.config.productionTip = false

Vue.prototype.msgSuccess = function (msg) {
    Message.success(msg)
}

Vue.prototype.msgError = function (msg) {
    Message.error(msg)
}
Vue.use(Image)
Vue.use(dataV)
Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')